import axios from 'axios';
import { isLoggedStore } from '../store/logged';


const API = axios.create({
  baseURL:'https://gestionalesimba.it/',
  //baseURL:'http://localhost:3000/',
  headers: { 
    'Access-Control-Allow-Origin': '*',  
  }
});

      // Function to refresh token
      const executeRefreshToken = async  () =>{

        const store = isLoggedStore();
        //const newToken = await API.get('/auth/refresh');

        const newToken = await axios.get('https://gestionalesimba.it/auth/refresh',{
        //const newToken = await axios.get('http://localhost:3000/auth/refresh',{
          headers: {
            'Access-Control-Allow-Origin': '*',  
            "Authorization": "Bearer " + localStorage.getItem("refresh_token") 
          },
        });

      
        // Update the token in localStorage
        store.accessToken=  newToken.data.access_token;
        store.refreshToken = newToken.data.refreshtoken;
        //localStorage.setItem('jwt', newToken.data.access_token);
        //localStorage.setItem('refresh_token', newToken.data.refreshToken);

        const authRefresh= parseJwt(newToken.data.refreshtoken);
        const auth= parseJwt(newToken.data.access_token);

        store.refreshExp = authRefresh.exp*1000;
        store.jwtExp =  auth.exp*1000;

        //localStorage.setItem('refreshExp', refreshExp);
        //localStorage.setItem('jwtExp', jwtExp);
          

      };
    
      
      const parseJwt= (token) =>{
        if (!token) {
          return;
        }
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
      
        return JSON.parse(window.atob(base64));
      
      }

// Add a request interceptor
API.interceptors.request.use(

  async (config) => {

    // Check if there's a token and if it's expired
    const token = localStorage.getItem('jwt');
    const tokenExpiry = localStorage.getItem('jwtExp');

    const refreshToken = localStorage.getItem('refresh_token');
    const refreshTokenExpiry = localStorage.getItem('refreshExp');

    config.headers.Authorization = `Bearer ${token}`;

    if (token && tokenExpiry && Date.now() >= parseInt(tokenExpiry, 10) && refreshToken && refreshTokenExpiry && Date.now() < parseInt(refreshTokenExpiry, 10)) {

      console.log("********* AGGIORNAMENTO TOKEN ******")
      try {

        //config.headers.Authorization = `Bearer ${refreshToken}`;

        // Refresh the token
        await executeRefreshToken();

        // Update the request config with the new token
        config.headers.Authorization = `Bearer ${localStorage.getItem('jwt')}`;

      } catch (error) {
        // Handle token refresh error
        console.error('Token refresh failed:', error);
        // Redirect to login page or handle the error as per your requirement
      }
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

export default API;