import API from './API';

export default {
  getHeadquartersByEmployee(employeeId) {
    return API.get(`/employee?filter=id||$eq||${employeeId}&`, {
      params: {
        join: ['headquarters', 'headquarters.area'],
      },
    });
  },

  getHeadquarters() {
    return API.get('/headquarter');
  },

  addTask(task) {
    return API.post('/task', task);
  },

  getBookingById(id) {
    return API.get(`/booking/` + id, {
      params: {
        join: [
          'pickup_bookings',
          'pickup_bookings.point',
          'pickup_bookings.driver_pickup',
          'pickup_bookings.planners',

          'delivery_bookings',
          'delivery_bookings.point_origin_delivery',
          'delivery_bookings.point_destination_delivery',
          'delivery_bookings.driver_delivery',
          'delivery_bookings.planners',

          'escort_bookings',
          'escort_bookings.point_origin_escort',
          'escort_bookings.point_destination_escort',
          'escort_bookings.driver_escort',
          'escort_bookings.planners',

          'customer',
          'tasks',
          'car',
          'headquarter',
          'headquarter.area',
        ],
      },
    });
  },

  getBookingAttivi(headquarterId, currentDate, tomorrowDate) {
    const state = 'canceled';
    return API.get(
      `/booking?filter=state||$ne||${state}&filter=data||$gte||${currentDate}&filter=data||$lte||${tomorrowDate}&filter=headquarter.id||$eq||${headquarterId}&sort=data,ASC`,
      {
        params: {
          join: [
            'car',
            'pickup_bookings',
            'delivery_bookings',
            'escort_bookings',
            'headquarter',
            'customer',
            'tasks',
          ],
        },
        order: { data: 'ASC' },
      }
    );
  },

  getBookingFuturi(headquarterId, tomorrowDate) {
    const state = 'canceled';
    return API.get(
      `/booking?filter=state||$ne||${state}&filter=data||$gte||${tomorrowDate}&filter=headquarter.id||$eq||${headquarterId}&sort=data,ASC`,
      {
        params: {
          join: [
            'car',
            'pickup_bookings',
            'delivery_bookings',
            'escort_bookings',
            'headquarter',
            'customer',
            'tasks',
          ],
        },
      }
    );
  },

  getBookingPassati(headquarterId, currentDate) {
    const state = 'canceled';

    return API.get(
      `/booking?filter=state||$ne||${state}&filter=data||$lt||${currentDate}&filter=headquarter.id||$eq||${headquarterId}&sort=data,ASC`,
      {
        params: {
          join: [
            'car',
            'pickup_bookings',
            'delivery_bookings',
            'escort_bookings',
            'headquarter',
            'customer',
            'tasks',
          ],
        },
      }
    );
  },

  getBookingAttiviAll(currentDate, tomorrowDate, areaId) {
    const state = 'canceled';
    if (areaId != '') {
      return API.get(
        `/booking?filter=state||$ne||${state}&filter=data||$gte||${currentDate}&filter=data||$lte||${tomorrowDate}&filter=headquarter.area.id||$eq||${areaId}&sort=data,ASC`,
        {
          params: {
            join: [
              'car',
              'pickup_bookings',
              'delivery_bookings',
              'escort_bookings',
              
              'pickup_bookings.driver_pickup',
              'delivery_bookings.driver_delivery',
              'escort_bookings.driver_escort',
              
              'headquarter',
              'customer',
              'tasks',
              'headquarter.partner',
              'headquarter.area',
            ],
          },
        }
      );
    } else {
      return API.get(
        `/booking?filter=state||$ne||${state}&filter=data||$gte||${currentDate}&filter=data||$lte||${tomorrowDate}&sort=data,ASC`,
        {
          params: {
            join: [
              'car',
              'pickup_bookings',
              'delivery_bookings',
              'escort_bookings',
              
              'pickup_bookings.driver_pickup',
              'delivery_bookings.driver_delivery',
              'escort_bookings.driver_escort',

              'headquarter',
              'customer',
              'tasks',
              'headquarter.partner',
            ],
          },
        }
      );
    }
  },

  getBookingFuturiAll(tomorrowDate, areaId) {
    const state = 'canceled';
    if (areaId != '') {
      return API.get(
        `/booking?filter=state||$ne||${state}&filter=data||$gte||${tomorrowDate}&filter=headquarter.area.id||$eq||${areaId}&sort=data,ASC`,
        {
          params: {
            join: [
              'car',
              'pickup_bookings',
              'delivery_bookings',
              'escort_bookings',
              
              'pickup_bookings.driver_pickup',
              'delivery_bookings.driver_delivery',
              'escort_bookings.driver_escort',

              'headquarter',
              'customer',
              'tasks',
              'headquarter.partner',
              'headquarter.area',
            ],
          },
        }
      );
    } else {
      return API.get(
        `/booking?filter=state||$ne||${state}&filter=data||$gte||${tomorrowDate}&sort=data,ASC`,
        {
          params: {
            join: [
              'car',
              'pickup_bookings',
              'delivery_bookings',
              'escort_bookings',
              
              'pickup_bookings.driver_pickup',
              'delivery_bookings.driver_delivery',
              'escort_bookings.driver_escort',

              'headquarter',
              'customer',
              'tasks',
              'headquarter.partner',
            ],
          },
        }
      );
    }
  },

  getBookingPassatiAll(currentDate, areaId) {
    const state = 'canceled';
    if(areaId!=""){
      return API.get(
        `/booking?filter=state||$ne||${state}&filter=data||$lte||${currentDate}&filter=headquarter.area.id||$eq||${areaId}&sort=data,ASC`,
        {
          params: {
            join: [
              'car',
              'pickup_bookings',
              'delivery_bookings',
              'escort_bookings',
              
              'pickup_bookings.driver_pickup',
              'delivery_bookings.driver_delivery',
              'escort_bookings.driver_escort',

              'headquarter',
              'customer',
              'tasks',
              'headquarter.partner',
              'headquarter.area',
            ],
          },
        }
      );
    }else{
      return API.get(
        `/booking?filter=state||$ne||${state}&filter=data||$lte||${currentDate}&sort=data,ASC`,
        {
          params: {
            join: [
              'car',
              'pickup_bookings',
              'delivery_bookings',
              'escort_bookings',
              
              'pickup_bookings.driver_pickup',
              'delivery_bookings.driver_delivery',
              'escort_bookings.driver_escort',

              'headquarter',
              'customer',
              'tasks',
              'headquarter.partner',
            ],
          },
        }
      );
    }
   
  },

  /*getBookingInConfermaAll() {
    const state = "canceled";
    //return API.get(`/booking?filter=state||$ne||${state}&filter=pickup_bookings.driver_pickup.is_alive||$eq||${true}||filter=escort_bookings.driver_escort.is_alive||$eq||${true}||filter=delivery_bookings.driver_delivery.is_alive||$eq||${true}&sort=data,ASC`, {
    return API.get(`/booking?filter=state||$ne||${state}&filter=escort_bookings.driver_escort.is_alive||$eq||${true}&sort=data,ASC`, {
      params: {
        join: ['car','pickup_bookings','delivery_bookings','escort_bookings', 'headquarter', 'customer', 'tasks', 'pickup_bookings.driver_pickup', 'escort_bookings.driver_escort', 'delivery_bookings.driver_delivery'],

      },
    });
  },*/

  updateDateBooking(id, data) {
    return API.patch('/booking/' + id, data);
  },

  /***************************PICKUP**********************/
  getRangePickup(req) {
    return API.post('/pickup/range', req);
  },

  /* updateDatePickup(id,date){
    return API.patch('/pickup/'+id, date)
  }, */
  updateDatePickup(id, date) {
    return API.post('/pickup/update-prenotazione/' + id, date);
  },

  /***************************ESCORT **********************/
  getRangeEscort(req) {
    return API.post('/escort/range', req);
  },

  updateDateEscort(id, date) {
    return API.post('/escort/update-prenotazione/' + id, date);
  },

  /***************************DELIVERY**********************/
  getRangeDelivery(req) {
    return API.post('/delivery/range', req);
  },

  updateDateDelivery(id, date) {
    return API.post('/delivery/update-prenotazione/' + id, date);
  },

  messageModifyHourBooking(data) {
    return API.post('/booking/messageModifyHourBooking/', data);
  },

  messageModifyTaskBooking(data) {
    return API.post('/booking/messageModifyBooking/', data);
  },

  patchBooking(id) {
    return API.patch('/state', id);
  },

  acceptBooking(id) {
    return API.post('/booking/accept', id);
  },

  deleteBooking(id) {
    return API.get('/booking/delete/' + id);
  },
};
